import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import { SiteConfig } from '../../types/site-metadata';
import ContentMainColumn from '../ContentMainColumn';

import s from './s.module.scss';

const Header: React.FC = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: photoData }
    },
    site: {
      siteMetadata: {
        contacts: { email, telegram, facebook, linkedin, github, gitlab }
      }
    }
  } = useStaticQuery<SiteConfig>(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "IMG_20190322_141217.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      site {
        siteMetadata {
          contacts {
            email
            telegram
            facebook
            linkedin
            github
            gitlab
          }
        }
      }
    }
  `);

  return (
    <div>
      <ContentMainColumn>
        <div className={s.photo}>
          <Img fluid={photoData} alt="Tit Kovalenko - Photo" />
        </div>

        <div className={s.title}>
          <h1>TIT KOVALENKO</h1>
          <h3>Software Developer</h3>
        </div>

        <div className={s.linkIconsRow}>
          <a
            className={s.linkIcon}
            rel="noreferrer"
            target="_blank"
            href={telegram}
          >
            <i>telegram</i>
          </a>

          <a
            className={s.linkIcon}
            rel="noreferrer"
            target="_blank"
            href={linkedin}
          >
            <i>linkedin</i>
          </a>

          <a
            className={s.linkIcon}
            rel="noreferrer"
            target="_blank"
            href={facebook}
          >
            <i>facebook</i>
          </a>

          <a
            className={s.linkIcon}
            rel="noreferrer"
            target="_blank"
            href={github}
          >
            <i>github</i>
          </a>

          <a
            className={s.linkIcon}
            rel="noreferrer"
            target="_blank"
            href={gitlab}
          >
            <i>gitlab</i>
          </a>
        </div>

        <div className={s.linkIconsRow}>
          <a
            className={cx(s.linkIcon, s.mailIcon)}
            rel="noreferrer"
            target="_blank"
            href={`mailto:${email}`}
          >
            <i>mail</i>
            <span>t.a.kvlnk@gmail.com</span>
          </a>
        </div>
      </ContentMainColumn>
    </div>
  );
};

export default Header;
