import React from 'react';

import Footer from '../Footer';
import Header from '../Header';
import Seo, { SeoProps } from '../Seo';

import s from './s.module.scss';

export interface PageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  children: () => React.ReactNode;
  seo: SeoProps;
}

const PageLayout: React.FC<PageLayoutProps> = props => {
  const {
    renderHeader = () => <Header />,
    children,
    renderFooter = () => <Footer />,
    seo
  } = props;

  return (
    <>
      <Seo {...seo} />
      <div className={s.root}>
        <header>{renderHeader()}</header>
        <main>{children()}</main>
        <footer>{renderFooter()}</footer>
      </div>
    </>
  );
};

export default PageLayout;
