import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { SiteConfig } from '../../types/site-metadata';

export interface SeoProps {
  description?: string;
  lang?: string;
  // TODO: find the reason why eslint error appears
  // eslint-disable-next-line no-undef
  meta?: JSX.IntrinsicElements['meta'][];
  title: string;
}

const Seo: React.FC<SeoProps> = props => {
  const { description = '', lang = 'en', meta = [], title } = props;

  const { site } = useStaticQuery<SiteConfig>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        ...meta
      ]}
    />
  );
};

export default Seo;
