import React from 'react';

import ContentMainColumn from '../ContentMainColumn';

import s from './s.module.scss';

const Footer: React.FC = () => {
  return (
    <div>
      <ContentMainColumn>
        <div className={s.content}>
          © {new Date().getFullYear()}, Tit Kovalnko.
          {` `}
          <span>
            Icons from <a href="https://icons8.com">icons8.com</a> was used.
          </span>
        </div>
      </ContentMainColumn>
    </div>
  );
};

export default Footer;
