import React from 'react';

import s from './s.module.scss';

const ContentMainColumn: React.FC = props => {
  const { children } = props;

  return <div className={s.root}>{children}</div>;
};

export default ContentMainColumn;
